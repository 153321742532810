import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import FormGroup from "../components/form-group"
import { Link } from "gatsby"
import axios from 'axios';
import { BarLoader } from 'react-spinners';

class BecomeAMember extends Component  {

  constructor(props) {
    super(props);

    this.state = {
      token: null,

      // uncomment necessary line when deploying to dev/prod
      apiBaseUrl: 'https://www.nicb.org', // prod
      // apiBaseUrl: 'https://nicb.dev.sandstorm.tech', // dev
      formData: {
        'webform_id': 'ncttf_become_a_contact',
        'address': {}
      },
      errors: {},

      // Form submit has loading animation when true
      submitting: false,

      // Form submit confirmation message displays when true
      success: false
    };

    this.businessTypes = [
      'Association Employee',
      'Government (Not Law Enforcement)',
      'Insurance',
      'Investigator',
      'Law Enforcement',
      'Legal',
      'Other',
      'Retailer',
      'Security Service Provider',
      'Shipper/Manufacturer',
      'Transportation/Logistics'
    ];

    this.formErrorsRef = React.createRef();
    this.honeypot = React.createRef();

    // Bind submit handler
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    event.preventDefault();

    let formData = this.state.formData;
    if (event.target.getAttribute('data-group') && event.target.getAttribute('data-group').length) {
      let group = event.target.getAttribute('data-group');
      formData[group][event.target.name] = event.target.value;
    }
    else {
      formData[event.target.name] = event.target.value;
    }

    this.setState({formData: formData});
  }

  handleSubmit(event) {
    event.preventDefault();
    let self = this;
    self.setState({submitting: true});

    // Initiate form submit logic if honeypot field has no value
    if (!this.honeypot.current.value) {
      let token = self.state.token;
      if (token === null) {
        axios.get(this.state.apiBaseUrl + '/session/token')
        .then(function(response) {
          token = response.data;
          self.setState({token: response.data});
        })
        .catch(function(error) {
          console.log(error.response.data);
          self.setState({submitting: false});
        });
      }

      axios({
        method: 'post',
        url: self.state.apiBaseUrl + '/webform_rest/submit',
        contentType: 'application/json',
        crossDomain: true,
        dataType: 'json',
        headers: {
          'X-CSRF-TOKEN': token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(self.state.formData),
      })
      .then(function (response) {
        // Form submit error
        if (response.data.hasOwnProperty('error') && Object.values(response.data.error).length > 0) {
          self.setState({errors: Object.values(response.data.error)});
          self.formErrorsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
        // Form submit success
        else {
          self.setState({success: true});
        }

        self.setState({submitting: false});
      })
      .catch(function(error) {
        if (error.response) {
          // Request made and server responded
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);

          if (error.response.data.hasOwnProperty('error') && Object.values(error.response.data.error).length > 0) {
            self.setState({errors: Object.values(error.response.data.error)});
            self.formErrorsRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }

        }
        else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        }
        else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
        // console.log('error/??');
        // console.log(error);
        self.setState({submitting: false});
      });
    }
    else {
      // If honeypot input is filled, pretend that the form submitted
      self.setState({success: true});
    }
  }

  render() {
    let topValidation = <div></div>;
    if (this.state.errors.length > 0) {
      topValidation = (
        <div className="form-errors" ref={this.formErrorsRef}>
          {
            this.state.errors.map((error, index) => {
              return (
                <div className="alert alert-danger" key={index}>{error}</div>
              );
            })
          }
        </div>
      );
    }

    let formContainer = (
      <div className="form-container">
        {topValidation}
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <FormGroup labelText="First Name:" labelFor="first_name" isRequired="true">
              <input className="form-control" id="first_name" name="first_name" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Last Name:" labelFor="last_name" isRequired="true">
              <input className="form-control" id="last_name" name="last_name" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Address 1:" labelFor="address1" isRequired="true">
              <input className="form-control" id="address1" name="address" data-group="address" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Address 2:" labelFor="address2">
              <input className="form-control" id="address2" name="address_2" data-group="address" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="City:" labelFor="city" isRequired="true">
              <input className="form-control" id="city" name="city" data-group="address" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="State/Province:" labelFor="state" isRequired="true">
              <input className="form-control" id="state" name="state_province" data-group="address" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Country:" labelFor="country" isRequired="true">
              <input className="form-control" id="country" name="country" data-group="address" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="County:" labelFor="county" isRequired="true">
              <input className="form-control" id="county" name="county" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Postal Code:" labelFor="postal_code" isRequired="true">
              <input className="form-control" id="postal_code" name="postal_code" data-group="address" type="text" onChange={this.handleInputChange} />
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup labelText="Phone 1:" labelFor="phone_1">
              <input className="form-control" id="phone_1" name="phone_1" type="tel" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Phone 2:" labelFor="phone_2">
              <input className="form-control" id="phone_2" name="phone_2" type="tel" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Email:" labelFor="email" isRequired="true">
              <input className="form-control" id="email" name="email" type="email" onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup labelText="Business Type:" labelFor="business_type" isRequired="true">
              <select className="form-control" id="business_type" name="business_type" onChange={this.handleInputChange}>
                <option value="">-- All Business Types --</option>
                {
                  this.businessTypes.map((businessType, index) => {
                    return (
                      <option key={index} value={businessType}>{businessType}</option>
                    );
                  })
                }
              </select>
            </FormGroup>
            <FormGroup labelText="Company/Agency:" labelFor="company_agency" isRequired="true">
              <input className="form-control" id="company_agency" name="company_agency" type="text" onChange={this.handleInputChange} />
            </FormGroup>
            <input ref={this.honeypot} style={{display: 'none'}} className="form-control" id="country" name="country" type="text" />
          </div>
          <button disabled={this.state.submitting || this.state.success} type="submit" className="nicb-button">
            <span>Submit</span>
            <BarLoader
              loading={this.state.submitting}
              width={50}
              color={'#006838'}
            />
          </button>
        </form>
      </div>
    );


    return (
      <Layout pageTitle="Become a Member" pathName={this.props.location.pathname}>
        <SEO title="Become a Member" />
        <Content>
          <div className="become-member">
            <p>Cargo theft is a national security issue and a major problem plaguing our economy. One goal of the website is to facilitate a collaborative effort to combat this serious crime by serving as a pointer system to connect people for assistance. There are no membership fees to join the task force.</p>
            <p>For additional information, please contact:</p>
            <address>
              Email: <a href="mailto:NCTTF@nicb.org">NCTTF@nicb.org</a><br />
            </address>
            <p><strong><Link to="/contacts">Search members</Link></strong></p>
            <p>Existing members: Please contact <a href="mailto:NCTTF@nicb.org">NCTTF@nicb.org</a> if any of your information has changed or you want to be removed from the contact list.</p>
            <div className="form-container">
              {this.state.success ? <h2>You have successfully submitted this form. We will get back to you.</h2> : formContainer}
            </div>
          </div>
        </Content>
      </Layout>
    );
  }

}

export default BecomeAMember
